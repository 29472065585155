import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css' 

import * as echarts from 'echarts'  
 
import service from './axios' // 引入封装好的axios和加载状态 
import utils from './utils' 

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)

app.mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 将service挂载到app实例上，以便全局使用  
app.config.globalProperties.$http = service

app.config.globalProperties.$utils = utils
// 将echarts挂载到Vue原型上
app.config.globalProperties.$echarts = echarts 

const debounce = (fn, delay) => {
  let timer
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}
    
const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor (callback) {
    callback = debounce(callback, 200)
    super(callback)
  }
}

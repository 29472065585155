import { createStore } from 'vuex'
export default createStore({
  state: {
   
    sourceUrl: '',
    requirementList: [],
    httpUrl: 'https://afh-f.xyz/api/v1'// 正式
    // httpUrl: 'http://39.99.176.179:20310/api/v1'// 测试
  
  },
  getters: {
  },
  mutations: {
    setRequirementList (state, obj) {
      state.requirementList = obj
    },
    setSourceUrl (state, url) {
      state.sourceUrl = url
    }

  },
  actions: {
  },
  modules: {
  }
})

// 标准时间转日期格式
const utils = {
  msToDate: function (msec) {
    const datetime = new Date(msec)
    const year = datetime.getFullYear()
    const month = datetime.getMonth()
    const date = datetime.getDate()
    const hour = datetime.getHours()
    const minute = datetime.getMinutes()
    const second = datetime.getSeconds()

    const result1 =
      year +
      '-' +
      (month + 1 >= 10 ? month + 1 : '0' + (month + 1)) +
      '-' +
      (date + 1 < 10 ? '0' + date : date) +
      ' ' +
      (hour + 1 < 10 ? '0' + hour : hour) +
      ':' +
      (minute + 1 < 10 ? '0' + minute : minute) +
      ':' +
      (second + 1 < 10 ? '0' + second : second)

    const result2 =
      year +
      '-' +
      (month + 1 >= 10 ? month + 1 : '0' + (month + 1)) +
      '-' +
      (date + 1 < 10 ? '0' + date : date)

    const result = {
      hasTime: result1,
      withoutTime: result2
    }

    return result
  },
  downloadFile: function (url) {
    // 创建一个隐藏的a标签
    const a = document.createElement('a')
    a.href = url
    // a.download = filename // 设置下载文件名
    // a.style.display = 'none' // 隐藏元素
    document.body.appendChild(a)
    a.click() // 模拟点击下载文件
    document.body.removeChild(a) // 下载完成移除元素
  },
  getRandomInt (min, max) {  
    min = Math.ceil(min) // 最小值是包含在内的  
    max = Math.floor(max) // 最大值是包含在内的  
    return Math.floor(Math.random() * (max - min + 1)) + min  
  },
  getNowTime: function () {
    const date = new Date()

    // 获取时间：时分秒
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    const secound = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    
    const datetime = hour + ':' + minute + ':' + secound

    // 获取日期：年月日
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const nowDate = year + '年' + month + '月' + day + '日'

    // 获取星期几
    const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
    const nowWeek = weeks[new Date().getDay()]
    return {
      nowDate,
      datetime,
      nowWeek
    }
  }
}

export default utils

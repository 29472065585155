import axios from 'axios'
import { ElLoading, ElMessage } from 'element-plus'
import router from '../router'

// 创建axios实例
const service = axios.create({

  // baseURL: 'http://10.102.10.239:5000', // 7楼地址
  baseURL: 'https://afh-f.xyz/api/v1', // 正式
  // baseURL: 'http://39.99.176.179:20310/api/v1', // 测试
  timeout: 30000
})
let loading = null

// 请求参closeLoading 是否关闭加载中   true关闭   false不关闭

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // console.log(config, 'config========')
    // 请求开始时显示加载状态

    const token = sessionStorage.getItem('token')
    if (token) {
      config.headers.Authorization = token
    }

    if (!config.closeLoading) {
      loading = ElLoading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 将加载实例保存到请求配置中
      config.loading = loading
    }

    return config
  },
  (error) => {
    // 请求错误处理
    if (loading) {
      loading.close()
    }

    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 请求成功处理
    if (loading) {
      loading.close()
    }

    return response.data
  },
  (error) => {
    // 请求失败处理
    console.log(error, 'error>>>>>')
    if (error.response.data) {
      if (
        error.response.data.code === -5 ||
          error.response.data.code === -4
      ) {
        sessionStorage.removeItem('token')
        router.push('/')
      } else {
        ElMessage({
          message: error.response.data.error,
          type: 'warning'
        })
      }
    } else {
      ElMessage({
        message: error.response.error,
        type: 'warning'
      })
    }

    if (loading) {
      loading.close()
    }
    return Promise.reject(error)
  }
)

export default service
